import $ from 'cash-dom'
import { setCookie } from '../utilities/cookies'
import { getURLParam } from '../utilities/toolkit'

const isHomepage = window.Pura?.template.name === 'index'
const formType = window.location.hash || false

const popup = $('[data-section-id="popup"]')
const content = $('#popup-content')
const subscribedToNewsletter = getURLParam('customer_posted')
const testingParam = getURLParam('disable_newsletter')

const monitorPanelState = (panelSelector: string, eventName: string) => {
  const panel = $(panelSelector)
  if (panel.length) {
    const domElement = panel[0]
    if (!domElement._observer) {
      domElement._observer = new MutationObserver((mutationsList, observer) => {
        const isOpen = panel.attr('aria-hidden') === 'false'
        if (!isOpen) {
          panel.trigger(eventName)
          observer.disconnect()
          domElement._observer = null
        }
      })
    } else {
      domElement._observer.disconnect()
    }
    domElement._observer.observe(domElement, {
      attributes: true,
      attributeFilter: ['aria-hidden'],
    })
  }
}
const checkDeviceType = () => {
  const mobileWidthThreshold = 768
  const screenWidth = window.innerWidth
  return screenWidth <= mobileWidthThreshold ? 'mobile' : 'desktop'
}

const toggleContentForMobile = () => {
  const deviceType = checkDeviceType()
  if (!popup.hasClass('hidden')) {
    if (deviceType === 'mobile') {
      content.removeClass('translate-y-full')
      content.addClass('translate-y-0')
    } else {
      content.addClass('translate-y-full')
      content.removeClass('translate-y-0')
    }
  }
}

const showNewsletterPopup = (time = 1000) => {
  setTimeout(() => {
    const cartPanel = $('#cart')
    const accountPanel = $('#account-panel')

    const isCartOpen =
      cartPanel.length && cartPanel.attr('aria-hidden') === 'false'
    const isAccountPanelOpen =
      accountPanel.length && accountPanel.attr('aria-hidden') === 'false'

    if (isCartOpen) {
      monitorPanelState('#cart', 'close')
      $($('#cart')).on('close', () => {
        showNewsletterPopup(3000)
      })
    }

    if (isAccountPanelOpen) {
      monitorPanelState('#account-panel', 'close')
      $($('#account-panel')).on('close', () => {
        showNewsletterPopup(3000)
      })
    }

    if (
      !isCartOpen &&
      !isAccountPanelOpen &&
      popup.length &&
      testingParam !== 'true'
    ) {
      popup.attr('aria-hidden', 'false')
      popup.removeClass('hidden')

      const $focusTrapStart = $('#sms-email-section focus-trap-start')
      const x = window.scrollX
      const y = window.scrollY
      $(window).on('resize', toggleContentForMobile)

      if ($focusTrapStart.length) {
        setTimeout(() => {
          $focusTrapStart[0].focus()
          toggleContentForMobile()
          window.scrollTo(x, y)
        }, 200)
      }

      setCookie('signup-popup', 1, 7)
    }
  }, time)
}

const closePopup = () => {
  popup.addClass('hidden')
  window.removeEventListener('resize', toggleContentForMobile)
}

$('[data-action="close-popup"]').on('click', closePopup)

const previouslySubscribed = (formType) => {
  $(formType).hide()
  $(formType)
    .parentsUntil('section')
    .find('[data-component="previously-subscribed"]')
    .show()
}

if (isHomepage && formType === '#newsletter-popup') {
  showNewsletterPopup()
}

if (formType === '#newsletter-footer' || formType === '#newsletter-popup') {
  if (!subscribedToNewsletter) {
    previouslySubscribed(formType)
  }
}

$('.input-group-radio input[type="radio"]').on('change', (event) => {
  const form = $(this).closest('form')
  const emailForm = form.find('[data-component="email-form"]')
  const smsForm = form.find('[data-component="sms-form"]')
  const smsTerms = form
    .parentsUntil('section')
    .find('[data-element="sms-legal-terms"]')

  if (event.value === 'email') {
    emailForm.removeClass('hidden')
    smsForm.addClass('hidden')
    smsTerms.addClass('hidden')
  } else {
    emailForm.addClass('hidden')
    smsForm.removeClass('hidden')
    smsTerms.removeClass('hidden')
  }
})

$('[data-element="braze-sign-up-form"]').on('click', function (e) {
  const segment = window.analytics

  segment?.track('Form Submitted', {
    'form': 'website modal discount offer',
    'value': 10,
  })

  const form = $(e.target.closest('form'))
  const email = form.find('[name="contact[email]"]').val()
  const phone = form.find('[name="contact[phone]"]').val()

  if (email) {
    segment?.identify({
      email: email,
      discountpercentmodal: 10,
    })
  }

  if (phone) {
    segment?.identify({
      phone: phone,
      discountpercentmodal: 10,
    })
  }

  if (!email) {
    e.preventDefault()
    let section = form.parentsUntil('section')
    let smsLegalTerms = section.find('[data-element="sms-legal-terms"]')
    let smsSuccessMessage = section.find('[data-component="sms-subscribed"]')

    if (phone) {
      $(form).addClass('hidden')
      $(smsLegalTerms).addClass('hidden')
      $(smsSuccessMessage).show()
    }
  }
})

export { showNewsletterPopup }
